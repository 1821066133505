(function ($) {
    var defaults = {
        nestable: {
            listNodeName: 'ol',
            itemNodeName: 'li',
            rootClass: 'nestable',
            listClass: 'nestable-list',
            itemClass: 'nestable-item',
            dragClass: 'nestable-dragel',
            handleClass: 'nestable-handle',
            collapsedClass: 'nestable-collapsed',
            placeClass: 'nestable-placeholder',
            noDragClass: 'nestable-nodrag',
            emptyClass: 'nestable-empty',
            expandBtnHTML: '<button class="btn btn-link fa fa-plus-square-o" data-action="expand"></button>',
            collapseBtnHTML: '<button class="btn btn-link fa fa-minus-square-o" data-action="collapse"></button>'
        }
    };
    
    $.fn.LinkSelector = function (options) {
        
        var $modal;
        var $container = $(this);
        var options = $.extend(true, {}, defaults, options);
        
        initModal();
        initInputs();
        initButtons();
        
        function initModal(){
            $modal = $(
                '<div class="modal fade link-selector-modal">'+
                    '<div class="modal-dialog">'+
                        '<div class="modal-content">'+
                            '<div class="modal-header">'+
                                '<h4 class="modal-title pull-left">Pagina selecteren</h4>'+
                                '<button type="button" class="btn btn-xs btn-default pull-right" data-dismiss="modal"><i class="fa fa-remove"></i></button>'+
                                '<button type="button" class="btn btn-xs btn-default pull-right" data-button="refresh"><i class="fa fa-refresh"></i></button>'+
                            '</div>'+
                            '<div class="modal-body"></div>'+
                            '<div class="modal-footer">'+
                                '<button type="button" class="btn btn-default" data-dismiss="modal">Sluiten</button>'+
                                '<button type="button" class="btn btn-success" data-button="add">Toevoegen</button>'+
                            '</div>'+
                        '</div>'+
                    '</div>'+
                  '</div>'
            ).appendTo($('body'));
      
            $modal.modal({
                show: false
            }).on('show.bs.modal', function (e) {
                if($modal.find('.nestable-list .nestable-item').length == 0){
                    getPages();
                }
            });
        }
        
        function initInputs(){
            $container.find('[data-input=visible]').keyup(function(){
                input = $(this);
                
                value = formatLink( input.val());
                input.val(value);
                $container.find('[data-input=hidden]').val(value);
            });
        }
        
        function initButtons(){
            $container.find('[data-button=show]').click(function(){
                $modal.modal('show');
            });
            
            $modal.find('[data-button=refresh]').click(function(){
                getPages();
            });
                    
            $modal.find('[data-button=add]').click(function(){
                add();
            });
        }
        
        function add(){
            selected = $modal.find('.selected');
            if(selected.length > 0){
                $container.find('[data-input=visible]').val(selected.data('uri'));
                $container.find('[data-input=hidden]').val(selected.data('value'));
                
                $modal.modal('hide');
            }
        }
        
        function getPages(){
            var body = $modal.find('.modal-body');
            var loader = $('<div class="alert alert-info"><i class="fa fa-refresh fa-spin"></i> Bezig met laden, moment geduld</div>');
            var msg = $('<div class="alert alert-warning">Geen pagina\'s gevonden</div>');
            
            $.ajax({
                dataType: 'json',
                method: 'GET',
                headers: {
                    'X-XSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                url: $container.data('url'),
                beforeSend: function(){
                    $modal.find('.alert').remove();
                    body.empty().before(loader);
                },
                success: function(response) {
                    $modal.find('.alert').remove();
                    
                    if(response.length > 0){
                        $('<div class="nestable"><ol class="nestable-list"></ol></div>').appendTo(body);
                        list = body.find('.nestable-list');
                        
                        $(response).each(function(i, data){
                            element = buildElement(data).appendTo(list);

                            element.click(function(event){
                                el = $(event.target).parent();
                                if(el.hasClass('selected')){
                                    el.removeClass('selected');
                                }else{
                                    list.find('.nestable-item').removeClass('selected');
                                    el.addClass('selected');
                                }
                            }).dblclick(function(event){
                                $(event.target).parent().addClass('selected');
                                $modal.find('[data-button=add]').trigger('click');
                            });
                        });
                        
                        list.nestable(options.nestable);
                    }else{
                        body.before(msg);
                    }
                }
            });
        }
        
        function buildElement(data){
            var element = $('<li class="nestable-item" data-value="page.'+ data.id +'" data-uri="'+ data.uri +'"><div class="nestable-handle disabled">'+ data.title +'</div></li>');
            
            if(element.data('value') == $container.find('[data-input=hidden]').val()){
                element.addClass('selected');
            }
            
            if(data.children != undefined && data.children.length){
                var children = $('<ol class="nestable-list"></ol>').appendTo(element);
                $(data.children).each(function(){
                    child = this;
                    child.uri = data.uri +'/'+ child.link;
                    buildElement(child).appendTo(children);
                });
            }
            
            return element;
        }
      }
})(jQuery);

$(window).ready(function(){
    $('[data-toggle=link-selector]').LinkSelector();
});