(function ($) {
    var defaults = {
        url: {
            new: null,
            update: null,
        },
        nestable: {
            listNodeName: 'ol',
            itemNodeName: 'li',
            rootClass: 'nestable',
            listClass: 'nestable-list',
            itemClass: 'nestable-item',
            dragClass: 'nestable-dragel',
            handleClass: 'nestable-handle',
            collapsedClass: 'nestable-collapsed',
            placeClass: 'nestable-placeholder',
            noDragClass: 'nestable-nodrag',
            emptyClass: 'nestable-empty',
            expandBtnHTML: '<button class="btn btn-link fa fa-plus-square-o" data-action="expand"></button>',
            collapseBtnHTML: '<button class="btn btn-link fa fa-minus-square-o" data-action="collapse"></button>'
        },
        messages: {
            new: 'Nieuwe pagina',
            warning: 'Er is al een nieuwe pagina toegevoegd,<br>graag eerst deze wijzigen en opslaan'
        }
    };
    
    $.fn.tree = function (options) {
        
        var container = $(this);
        var options = $.extend(true, {}, defaults, options);
        
        initNestable();
        initAddButtons();
        initShowButtons();
        initActiveButtons();
        initDeleteButtons();
        
        function initNestable(){
            container.nestable(options.nestable).on('change', function(event) {
                update();
            });
            
            $('[data-nestable=collapse]').click(function(event){
                container.nestable('collapseAll');
            });
            
            $('[data-nestable=expand]').click(function(event){
                container.nestable('expandAll');
            });
        }
        
        function update(){
            $.ajax({
                dataType: 'json',
                method: 'PUT',
                headers: {
                    'X-XSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                url: options.url.update,
                data: { 
                    data: container.nestable('serialize')
                }
            }).done(function (response) {
                if (!response.error) {
                    notify('success', response.message);
                } else {
                    notify('danger', response.message);
                }
            });
        }
        
        function initAddButtons(){
            $('[data-nestable=add]').click(function(event){
                event.preventDefault();
                
                if(container.find('.nestable-handle.new').length == 0){
                    createItem(0, container.find('> .nestable-list > .nestable-item > .nestable-handle').length + 1).appendTo(container.find('.nestable-list').first());
                }else{
                    notify('warning', options.messages.warning);
                }
            });
            
            container.find('[data-action=add]').click(function(event){
                event.preventDefault();
                
                element = $(this).parents('.nestable-item').first();
                if(container.find('.nestable-handle.new').length == 0){
                    parent = element.parents('.nestable-item').first();
                    parent = (parent.length == 1 ? parent.data('id') : 0);
                    order = element.prevAll().length + 2;
                    
                    element.after(createItem(parent, order));
                }else{
                    notify('warning', options.messages.warning);
                }
            });
        }
        
        function createItem(parent, order){
            url = options.url.new +'?p='+ parent +'&o='+ order;
            
            var item = $(
                '<li class="nestable-item">'+
                    '<div href="#" class="nestable-handle disabled new">'+
                        '<a href="'+ url +'">' + options.messages.new + '</a>'+
                        '<div class="nestable-options">'+
                            '<a class="btn btn-xs btn-link"><i class="fa fa-fw fa-lock"></i></a>'+
                            
                            '<a href="'+ url +'" class="btn btn-xs btn-default">'+
                                '<i class="fa fa-fw fa-pencil"></i>'+
                            '</a>'+

                            '<a class="btn btn-xs btn-danger" data-action="delete">'+
                                '<i class="fa fa-fw fa-times"></i>'+
                            '</a>'+
                        '</div>'+
                    '</div>'+
                '</li>'
            );
            
            item.find('[data-action=delete]').click(function(event){
                event.preventDefault();
                item.fadeOut();
                setTimeout(function(){
                    item.remove();
                }, 400);
            });

            return item;
        }
        
        function initShowButtons(){
            container.find('[data-action=show]').click(function(event){
                event.preventDefault();
                
                var button = $(this);
                
                $.ajax({
                    dataType: 'json',
                    method: 'PUT',
                    headers: {
                        'X-XSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    url: button.attr('href')
                }).done(function (response) {
                    if (!response.error) {
                        button.parents('.nestable-handle').first().toggleClass('notvisible');
                        
                        icon = button.find('i');
                        if(icon.hasClass('text-success')){
                            icon.removeClass('fa-eye text-success').addClass('fa-eye-slash text-danger');
                        }else{
                            icon.removeClass('fa-eye-slash text-danger').addClass('fa-eye text-success');
                        }
                        
                        notify('success', response.message);
                    } else {
                        notify('danger', response.message);
                    }
                });
            });
        }
        
        function initActiveButtons(){
            container.find('[data-action=active]').click(function(event){
                event.preventDefault();
                
                var button = $(this);
                
                $.ajax({
                    dataType: 'json',
                    method: 'PUT',
                    headers: {
                        'X-XSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    url: button.attr('href')
                }).done(function (response) {
                    if (!response.error) {
                         button.parents('.nestable-handle').first().toggleClass('inactive');
                        
                        icon = button.find('i');
                        if(icon.hasClass('text-success')){
                            icon.removeClass('fa-check text-success').addClass('fa-remove text-danger');
                        }else{
                            icon.removeClass('fa-remove text-danger').addClass('fa-check text-success');
                        }
                        
                        notify('success', response.message);
                    } else {
                        notify('danger', response.message);
                    }
                });
            });
        }
        
        function initDeleteButtons(){
            container.find('[data-action=delete]').confirmation({
                placement: 'left',
                title: 'Weet u het zeker?',
                btnOkClass: 'btn btn-xs btn-danger',
                btnOkLabel: 'Ja',
                btnCancelLabel: 'Nee',
                btnOkIcon: 'fa fa-check',
                btnCancelIcon: 'fa fa-times',
                singleton: true,
                popout: true,
                onConfirm: function () {
                    var el = $(this);
                    $.ajax({
                        dataType: 'json',
                        method: 'DELETE',
                        headers: {
                            'X-XSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        },
                        url: el.attr('data-url')
                    }).done(function (response) {
                        if (!response.error) {
                            el.closest('.nestable-item').fadeOut();
                            notify('success', response.message);
                            
                            setTimeout(function(){
                                item.remove();
                                update();
                            }, 400);
                        } else {
                            notify('danger', response.message);
                        }
                    });
                }
            });
        }
    }
})(jQuery);