var initNavigation = function () {
    // init menu button
    $('[data-toggle=navigation]').click(function () {
        $('#wrapper').toggleClass('show-nav');
    });
};

var initTooltip = function () {
    $('[data-toggle="tooltip"]').tooltip();

    $('table[data-toggle=table]').on('load-success.bs.table', function () {
        $(this).find('[data-toggle="tooltip"], [data-toggle="confirmation"]').tooltip();
    });
};

var initTabs = function () {
    if ($('#tabProperties').length > 0) {
        $(window).resize(function () {
            if ($(window).width() > 1440 && $('#tabProperties').hasClass('active')) {
                $('ul.nav-tabs [data-toggle=tab]').first().click();
            }
        });
    }
};

var initDatePicker = function (elements) {
    moment.locale($('html').attr('lang'));

    elements.each(function () {
        var element = $(this);
        var hidden = element.parent().find('input[type=hidden]');
        element.pikaday({
            format: moment.localeData()._longDateFormat.L,
            i18n: {
                months: moment.localeData()._months,
                weekdays: moment.localeData()._weekdays,
                weekdaysShort: moment.localeData()._weekdaysShort
            },
            onSelect: function (date) {
                hidden.val(moment(date).format('YYYY-MM-DD'));
            }
        }).pikaday('setMoment', moment(hidden.val(), 'YYYY-MM-DD'));
    });
};

var initDateTimePicker = function (elements) {
    elements.each(function () {
        var element = $(this);
        var hidden = element.parent().find('input[type=hidden]');
        element.datetimepicker({
            locale: $('html').attr('lang'),
            icons: {
                clear: "fa fa-trash-o",
                previous: "fa fa-chevron-left",
                next: "fa fa-chevron-right",
                time: "fa fa-clock-o",
                date: "fa fa-calendar",
                up: "fa fa-arrow-up",
                down: "fa fa-arrow-down"
            },
            showClear: true
        }).on("dp.change", function (e) {
            if (e.date) {
                hidden.val(moment(e.date).format('YYYY-MM-DD HH:mm:ss'));
            } else {
                hidden.val('');
            }
        });
    });
};

var notify = function (type, message) {
    $('.bottom-right').notify({
        type: type,
        closable: false,
        message: {html: message}
    }).show();
};

var initSelect = function (elements) {
    elements.selectpicker({
        noneSelectedText: 'Maak een keuze',
        liveSearchPlaceholder: 'Zoeken',
        iconBase: 'fa',
        tickIcon: 'fa-check'
    });
};

var initEditor = function (elements) {
    if (elements.length > 0) {
        $.getScript('//s3.eu-central-1.amazonaws.com/topsite-cms4-assets/js/tinymce/tinymce.min.js', function () {
            tinymce.init({
                selector: '[data-toggle=editor][data-type=standard]',
                relative_urls: false,
                language_url: 'https://s3.eu-central-1.amazonaws.com/topsite-cms4-assets/js/tinymce/langs/nl.js',
                language: $('html').attr('lang'),
                height: "480",
                plugins: "table link image code moxiemanager lists anchor media",
                external_plugins: {
                    "moxiemanager": "/moxie/plugin.min.js"
                },
                link_list: '/cms/pages/list',
                menubar: false,
                toolbar: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | anchor link image media | bullist numlist | table | removeformat | code",
                extended_valid_elements: 'script[language|type|src]'
            });

            tinymce.init({
                selector: '[data-toggle=editor][data-type=form]',
                relative_urls: false,
                language_url: 'https://s3.eu-central-1.amazonaws.com/topsite-cms4-assets/js/tinymce/langs/nl.js',
                language: $('html').attr('lang'),
                height: "480",
                plugins: "table link image code moxiemanager lists anchor forms media",
                external_plugins: {
                    "moxiemanager": "/moxie/plugin.min.js",
                    "forms": "/tinymce/forms/plugin.min.js"
                },
                link_list: '/cms/pages/list',
                menubar: false,
                toolbar: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | anchor link image media | bullist numlist | table | removeformat | forms | code",
                extended_valid_elements: 'script[language|type|src]'
            });
        });
    }
};

var initSwitch = function (elements) {
    elements.bootstrapSwitch({
        size: 'small',
        onColor: 'success',
        offColor: 'danger',
        onText: $('html').attr('lang') == 'nl' ? 'Ja' : 'Yes',
        offText: $('html').attr('lang') == 'nl' ? 'Nee' : 'No',
        onSwitchChange: function (event, state) {
            $(this).val(state ? 1 : 0).prop("checked", true);
        }
    });
};

var initDeleteButton = function () {
    $('table[data-toggle=table]').on('all.bs.table', function () {
        $('[data-toggle="delete-button"]').confirmation({
            placement: 'left',
            title: $('html').attr('lang') == 'nl' ? 'Weet u het zeker?' : 'Are you sure?',
            btnOkClass: 'btn btn-xs btn-danger',
            btnOkLabel: $('html').attr('lang') == 'nl' ? 'Ja' : 'Yes',
            btnCancelLabel: $('html').attr('lang') == 'nl' ? 'Nee' : 'No',
            btnOkIcon: 'fa fa-check',
            btnCancelIcon: 'fa fa-times',
            singleton: true,
            popout: true,
            onConfirm: function () {
                var el = $(this);
                var tr = el.parents('tr');

                $.ajax({
                    dataType: 'json',
                    method: 'DELETE',
                    headers: {
                        'X-XSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    url: el.attr('data-url')
                }).done(function (response) {
                    if (!response.error) {
                        tr.fadeOut();
                        notify('success', response.message);
                    } else {
                        notify('danger', response.message);
                    }
                });
            }
        });
    });
};

var initUploader = function (elements) {
    $(elements).each(function () {
        $(this).Uploader();
    });
};

var formatLink = function (value) {
    value = value.toLowerCase();
    value = value.replace(/ /g, '-');
    value = value.replace(/[^a-z0-9\.\:\/_-]/, '-');
    value = value.replace(/"/, '');
    value = value.replace(/-+/g, '-');
    //value = value.replace(/-$/, '');
    //value = value.replace(/.html$/, '');

    return value;
};

$(function () {
    initNavigation();
    initTooltip();
    initTabs();
    initSelect($('select'));
    initDeleteButton();
    initEditor($('[data-toggle=editor]'));
    // initEditorWithForm($('[data-toggle=editor-form]'));
    initSwitch($('[data-toggle=switch]'));
    initDatePicker($('[data-toggle=datepicker]'));
    initDateTimePicker($('[data-toggle=datetimepicker]'));
    initUploader($('[data-toggle=uploader]'));
});